import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';
import { getProduct, getProductLogs, getProductSettings } from '../../services/api/queryService';
import { useQuery } from '@apollo/react-hooks';
import Axios from 'axios';

/** Components */
import Table from '../../components/table/Table';
import '../product/Product.scss';
import DetailsHeader from '../../components/details-header/DetailsHeader';
import routes from '../../lib/constants/routes';
import RequiredDataModal from './RequiredDataModal';


const ProductLogs = ({ getProductTitle }) => {
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [productLogData, setProductLogs] = useState([]);
  const history = useHistory();
  const location = useLocation();

  const [ openRequiredDataModal, setOpenRequiredDataModal ] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({ requiredData: '' });
  function handleShow(requiredData){
    setCurrentModalData({
      requiredData: requiredData,
    });
    setOpenRequiredDataModal(true);
  }
  const handleModalClose = () => setOpenRequiredDataModal(false);

  let _uri = 'https://staging-api.ebazaaris.io'
  if (process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'prod_azure') {
    _uri = 'https://azure-api.ebazaaris.io'
  } else if (process.env.REACT_APP_ENV === 'dev') {
    _uri = 'http://localhost:8000'
  }

  const slug = location.pathname.split('/')[2]

  const tabClickHandlerEditLog = () => {
    history.push(`/products/${slug}/logs`);
  };

  const tabClickHandler = tabId => {
    switch(tabId){
      case 'listings':
        history.push(`/listings/${slug}/edit`);
        break;
      case 'unit-economics':
        history.push(`/unit-economics/${slug}`);
        break;
      case 'product':
        history.push(`/products/${slug}/edit`);
        break;
      case 'images':
          history.push(`/listings/${slug}/images`);
          break;
      default:
        history.push(`/products/${slug}/edit`);
    }
  };

  const { data: productData, refetch } = useQuery(getProduct, {
    variables: { slug: slug }
  });
  
  useEffect(() => { 
    if(productData && productData.product){
      let pckgName = ''
      if(+productData.product.product_type_id !== 8){
        pckgName = `| ${productData.product.packagingUnit?.name}`
      }

      getProductTitle(`${productData.product.product_name} ${pckgName}`)
    }
  }, [productData]);

  const {loading: logLoading, data: logData, refetch: refetchLogs } = useQuery(getProductLogs, {
    variables: { 
      slug: slug,
      externalSystems: ['plentymarkets', 'scoro', 'sitelucent', 'jtl'] 
    }
  });

  useEffect(() => {
    if(logData && logData.productLogs){
      setProductLogs(logData.productLogs);
    }
  }, [logData, logLoading]);


  return (
      <>
       <div className="row">
          <div className="col-12">
          <div className="page-title-box">
              <h4 className="page-title">
                Update log
              </h4>

              <div className="row">
              <div className="col-12">
                  <div className="page-title-box">
                  
                  <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                          <Link to="/">PIM</Link>
                      </li>
                      <li className="breadcrumb-item active">Update log</li>
                  </ol>
                  </div>
              </div>
              </div>
          </div>
          </div>
      </div>
        {/* end page title */}

        { productData && productData.product && <DetailsHeader renderFor="products" data={ !productData ?  '' : productData.product} /> }
        { openRequiredDataModal && <RequiredDataModal requiredData={currentModalData.requiredData} setModalOpen={openRequiredDataModal} handleModalClose={handleModalClose} /> }      

        <div className="product-tabs product-tabs-custom col-10">
          <div
          className={`tab`}
          onClick={tabClickHandler.bind(null, 'product')}
          >
          Information
          </div>
      
          <div
          className={`tab `}
          onClick={tabClickHandler.bind(null, 'listings')}
          >
          Content
          </div>
          <div
          className={`tab active`}
          onClick={null}
          >
          Update log
          </div>
          <div
          className={`tab`}
          onClick={tabClickHandler.bind(null, 'images')}
          >
          Images
          </div>
           <div
              className={`tab `}
              onClick={tabClickHandlerEditLog}
            >
              Edit log
            </div>
        <div className="tab-bar" />
      </div>

      {/* Check if we retrieved log data from the DB, then display that data */}
      {
        logLoading ? (
          <div className="spinner absolute">Spinner</div>
        ) : (
            <div className="sections-page-table" id="productLogs">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table id="basic-datatable" className="table table-hover dt-responsive nowrap">
                          <thead>
                              <tr>
                              <th>System</th>
                              <th>Last update</th>
                              <th>Status</th>
                              <th>Error</th>
                              </tr>
                          </thead>
                          <tbody>
                            { productLogData.map((log) => {
                              if(Object.keys(log).length > 0 && log.action === 'required-data-check' ) {
                                return (
                                    <tr>
                                      <td class="text-capitalize">{!log.external_system ? '' : (log.external_system === 'jtl' ? 'S&S Warehouse (JTL)' : log.external_system )}</td>
                                      <td>{!log.updated_at ? '' : log.updated_at.slice(0, 19).replace("T", " ") }</td>
                                      <td>{!log.error ? <span class="status-badge status-badge-active">OK</span> : <span class="status-badge status-badge-canceled">Error</span> }</td>
                                      <td>
                                        { !log.error ? '' : (
                                          <a style={{ cursor: 'pointer' }}  onClick={ () => handleShow( log.error ) }>
                                            <i style={{marginLeft: '5px'}} className='fas fa-info-circle red-info-icon'></i>
                                            <span style={{ textDecoration: 'underline', paddingLeft: '3px' }} onMouseEnter={(e) => e.target.style.color = '#ed585f'} onMouseLeave={(e) => e.target.style.color = 'inherit'}>Product cannot be synced due to missing data</span>
                                          </a>  
                                        )}
                                      </td>
                                    </tr>
                                )} else if (Object.keys(log).length > 0 && log.action === 'create-products' && log.error === 'Not creating yet. Bundle component missing in JTL') {
                                  return (
                                    <tr>
                                      <td class="text-capitalize">{!log.external_system ? '' : (log.external_system === 'jtl' ? 'S&S Warehouse (JTL)' : log.external_system )}</td>
                                      <td>{!log.updated_at ? '' : log.updated_at.slice(0, 19).replace("T", " ") }</td>
                                      <td>{!log.error ? <span class="status-badge status-badge-active">OK</span> : <span class="status-badge status-badge-canceled">Error</span> }</td>
                                      <td>
                                        { !log.error ? '' : (
                                          <a style={{ cursor: 'pointer' }}  onClick={ () => handleShow( log.error ) }>
                                            <i style={{marginLeft: '5px'}} className='fas fa-info-circle red-info-icon'></i>
                                            <span style={{textDecoration: 'underline', paddingLeft: '3px' }} onMouseEnter={(e) => e.target.style.color = '#ed585f'} onMouseLeave={(e) => e.target.style.color = 'inherit'}>{ log.error }</span>
                                          </a>  
                                        )}
                                      </td>
                                    </tr>
                                )} else if (Object.keys(log).length > 0 && log.action !== 'required-data-check') {
                                  return (    
                                    <tr>
                                      <td class="text-capitalize">{!log.external_system ? '' : (log.external_system === 'jtl' ? 'S&S Warehouse (JTL)' : log.external_system )}</td>
                                      <td>{!log.updated_at ? '' : log.updated_at.slice(0, 19).replace("T", " ") }</td>
                                      <td>{!log.error ? <span class="status-badge status-badge-active">OK</span> : <span class="status-badge status-badge-canceled">Error</span> }</td>
                                      <td>{!log.error ? '' : log.error}</td>
                                    </tr>
                                  );
                                }
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
      }
    </>
  );
};

export default ProductLogs;