import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import Table from '../../components/table/Table';
import { queryStringHandler, fetchData } from '../../services/util/dataHandlerService';
import { getProducts } from '../../services/api/queryService';

const description = {
  module: 'products',
  description: 'Products',
  req: 'searchProducts',
  is_product: true
};

const ManufacturerProductsTab = ({ location, manufacturerId, slug, canDelete }) => {
  const { first, page, keyword } = queryStringHandler(location.search);
  const { loading, data, refetch } = useQuery(getProducts, {
    variables: {
      first,
      page,
      input: keyword,
      order: 'product_parent_id',
      filter: `manufacturer_id=${manufacturerId}`,
      fromParams: null
    },
    fetchPolicy: 'no-cache'
  });

  description.url = `/manufacturers/${slug}/edit`;

  // if (data) {
  //   const productsData = Object.values(data)[0].data;
  //   productsData.forEach(product => {
  //     // eslint-disable-next-line
  //     for (let key in product) {
  //       if (typeof product[key] === 'object') {
  //         product[key] = product[key] && product[key].name;
  //       }
  //     }
  //   });

  // }

  return (
    <>
      {!data ? (
        <div className="spinner absolute">Spinner</div>
      ) : (
        <Table
          loading={loading}
          refetch={refetch}
          description={description}
          canDelete={canDelete}
          pagerData={{
            ...fetchData(description.req, data).pagerData,
            search: keyword
          }}
          tableData={fetchData(description.req, data).data}
          hasExport={true}
        />
      )}
    </>
  );
};

export default ManufacturerProductsTab;
