import React, { useState, useEffect, memo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import Input from '../ui-components/input/Input';
import { filter } from '../../services/api/queryService';
import { generateQueryStr, queryStrToObject } from '../../services/util/dataHandlerService';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const ExpandedTableFilter = ({ history, queryStr = '', module = '', tab, pagerData, filterType = 'product' }) => {


  const userData = useSelector(state => state.auth.user);
  const [activeManufacturers, setActiveManufacturer] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [modifiedDate, setModifiedDate] = useState([null, null]);
  const [startModifiedDate, endModifiedDate] = modifiedDate;

  useEffect(() => {
    let storageActiveManufacturers = JSON.parse(localStorage.getItem('active_manufacturers'));
    if(storageActiveManufacturers){
      storageActiveManufacturers = storageActiveManufacturers.map(item => item.id);
      setActiveManufacturer(storageActiveManufacturers);
    }
  }, []);


  window.addEventListener('activeManufacturerChange', (e) => {
    let aManufacturers = JSON.parse(localStorage.getItem('active_manufacturers'));
    aManufacturers = aManufacturers.map(item => item.id);
    setActiveManufacturer(aManufacturers);
  });

  const [filterData, setFilterData] = useState();
  const { loading, data } = useQuery(filter, {
    variables: { type: filterType, manufacturer_id: userData.user_type == 2 ? (activeManufacturers.length > 0 ? activeManufacturers.map(x => +x) : []) : userData.manufacturers }
  });

  useEffect(() => {
    if (!loading) {
      if (data) {
        data.filter = data.filter.filter((item) => {
          if (item.name !== 'plentymarkets_id') {
            return item
          }
        })

        const result = {};

        data.filter.forEach(item => {
          const optionsArray = JSON.parse(item.data);

          const InputTemplateObject = {
            inputType: 'select',
            inputConf: {
              placeholder: '',
              name: item.name,
              type: 'select'
            },
            label: item.alias || item.name,
            list: [],
            value: []
          };

          Object.values(optionsArray).forEach(option => {
            InputTemplateObject.list.push({
              label: option.name || option[item.name],
              value: option.id || option[item.name]
            });

            result[item.name] = InputTemplateObject;
          });
         
        });
        queryStrToObject(queryStr, result);
        setFilterData(result);
      }
    }
  }, [loading, data, queryStr]);

  const inputChangeHandler = (e, name) => {
    const updateFilterObject = {...filterData};
    updateFilterObject[name].value = e ? e : [];


    console.log(e, name)

    setFilterData({...updateFilterObject});
  };

  const onPasteHandler = (e, key) => {
    const updateFilterObject = {...filterData};
    const text = e.clipboardData.getData('Text')

    const fd = text.split(',').map(x => x.trim().toUpperCase())

    const fv = updateFilterObject[key].list.filter(x => fd.some(w => w == x.label))

    updateFilterObject[key].value = fv.length > 0 ? [...fv] : [];

    setFilterData({...updateFilterObject});

  }

  const submitHandler = () => {
    // filterData.perPage = pagerData.count;
    let url = generateQueryStr(filterData, pagerData.perPage, pagerData.search);
    // url += '&count=' + pagerData.count;
    // if (pagerData.search) {
    //   url += '&count=' + pagerData.search
    // }
    // url += '&' + filter;

    if(startDate && endDate){
      url += `&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(endDate).format('YYYY-MM-DD')}`
    }

    if(startModifiedDate && endModifiedDate){
      url += `&start_update_date=${moment(startModifiedDate).format('YYYY-MM-DD')}&end_update_date=${moment(endModifiedDate).format('YYYY-MM-DD')}`
    }

    if (tab) {
      url += `&tab=${tab}`;
    }
    history.push(`/${module}${url}`);
  };

  const resetHandler = () => {
    let url = `/${module}`;
    if (tab) {
      url += `?tab=${tab}`;
    }

    setDateRange([null, null])
    setModifiedDate([null, null])
    history.push(`${url}`);
  };


  if (loading || !filterData) {
    return <div className="spinner">Spinner</div>;
  }

  const changeHandler = dates => {
    setDateRange(dates)
  }

  const changeModifiedHandler = dates => {
    setModifiedDate(dates)
  }

  return (
    <div className="container col-12" >
      <div className="row row-cols-4">
        {Object.keys(filterData).map((key, index) => (
          <Input
            key={index}
            col="col-md-3"
            data={filterData[key]}
            inputChanged={e => inputChangeHandler(e, key)}
            onPaste={e => onPasteHandler(e, key)}
            
          />
        ))}

        {filterType == 'product' && (
          <div className="col-md-3 form-group mb-2" style={{ float: 'left', width: '25%', minWidth: '180px' }}>
              <label>Created at</label>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                selected={startDate}
                onChange={changeHandler}
                className="form-control"
              />
          </div>
        )}
        {filterType == 'product' && (
          <div className="col-md-3 form-group mb-2" style={{ float: 'left', width: '25%', minWidth: '180px' }}>
              <label>Last modified at</label>
              <DatePicker
                selectsRange={true}
                startDate={startModifiedDate}
                endDate={endModifiedDate}
                selected={startModifiedDate}
                onChange={changeModifiedHandler}
                className="form-control"
              />
          </div>
        )}

      </div>

      <div className="my-2">
        <button className="btn btn-secondary mr-2 px-3" onClick={resetHandler}>
          Reset
        </button>
        <button className="btn btn-primary px-3" onClick={submitHandler}>
          Search
        </button>
      </div>
    </div>
  );
};

export default memo(ExpandedTableFilter);
