import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';

import { updateProductLogistic } from '../../../services/api/mutationService';
import {
  convertDataToOptions,
  createDefaultOption
} from '../../../services/util/dataHandlerService';
import { getFBMSizes, getFBADimensions, getFulfilmentCosts } from '../../../services/api/queryService';
import Error from '../../../components/modal/ModalError';

const getDefaultOptionFromValue = (value, options) => {
  if (value) {
    const option = options.find(option => value === option.id);
    if (option) {
      return createDefaultOption(option);
    }
  }
};



const LogisticsInformationForm = ({ selectData, productData, refetch, update, updateFormData, errors }) => {
  const [updateProductLogisticMutation, { loading, error: updateError }] = useMutation(
    updateProductLogistic
  );
  let { data } = useQuery(getFBMSizes);

  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [formData, setFormData] = useState({
    article_weight: productData.productLogistic
      ? productData.productLogistic.article_weight
      : undefined,
    article_width: productData.productLogistic
      ? productData.productLogistic.article_width
      : undefined,
    article_height: productData.productLogistic
      ? productData.productLogistic.article_height
      : undefined,
    article_depth: productData.productLogistic
      ? productData.productLogistic.article_depth
      : undefined,
    article_unit_volume: productData.productLogistic
      ? productData.productLogistic.article_unit_volume
      : undefined,
    article_packaging_type_id: productData.productLogistic
      ? productData.productLogistic.article_packaging_type_id
      : undefined,
    logistical_unit_weight: productData.productLogistic
      ? productData.productLogistic.logistical_unit_weight
      : undefined,
    logistical_unit_width: productData.productLogistic
      ? productData.productLogistic.logistical_unit_width
      : undefined,
    logistical_unit_height: productData.productLogistic
      ? productData.productLogistic.logistical_unit_height
      : undefined,
    logistical_unit_depth: productData.productLogistic
      ? productData.productLogistic.logistical_unit_depth
      : undefined,
    logistical_unit_volume: productData.productLogistic
      ? productData.productLogistic.logistical_unit_volume
      : undefined,
    packaging_unit_logistical: productData.productLogistic
      ? productData.productLogistic.packaging_unit_logistical
      : undefined,
    packaging_unit_per_eur_pallet: productData.productLogistic
      ? productData.productLogistic.packaging_unit_per_eur_pallet
      : undefined,

    logistics_ve_height : productData.productLogistic?.logistics_ve_height??undefined,
    logistics_ve_width : productData.productLogistic?.logistics_ve_width??undefined,
    logistics_ve_depth : productData.productLogistic?.logistics_ve_depth??undefined,
    logistics_ve_weight : productData.productLogistic?.logistics_ve_weight??undefined,
    logistics_ve_unit_volume : productData.productLogistic ? Math.round(
      (+productData.productLogistic?.logistics_ve_width *
        +productData.productLogistic?.logistics_ve_height *
        +productData.productLogistic?.logistics_ve_depth) /
        1000
    ) : undefined ,
    fbm_size_id: productData.productLogistic ? productData.productLogistic.fbm_size_id : null,
    fba_size_id: productData.productLogistic ? productData.productLogistic.fba_size_id : null,
    shipping_unit_id: productData.productLogistic ? productData.productLogistic.shipping_unit_id : null
  });

  const [DPDUnit, setDPDUnit] = useState('')
  const [DHLUnit, setDHLUnit] = useState('')
  const [fulfillmentCostList, setFulfillmentCostList] = useState(null)
  const [FBADimensionsList, setFBADimensionsList] = useState([])
  const [validationErrror, setValidationError] = useState();
  const [errorClass, setErrorClass] = useState('error');
  const [errorsList, setErrorsList] = useState([]);
  
  let { data : fulfilmentCosts } = useQuery(getFulfilmentCosts);
  let { data : FBADimensions } = useQuery(getFBADimensions);

  useEffect(() => {
    setErrorsList(errors)
  }, [errors])

  const showError = key => {
    if(!errorsList[key]) return null

    return (
      <div className="custom_error_container">
        <img src="/assets/images/info-button.png" alt="" />
        <p>{errorsList[key]}</p>
      </div>
    )
  }

  useEffect(() => {
    if(fulfilmentCosts && formData && formData.article_weight){
      const fulfilmentCostsData = fulfilmentCosts.fulfilmentCosts.filter(x => x.status == 1)

      const aw = formData.article_weight;
      let d = fulfilmentCostsData.filter(x => x.shipping_carrier == 'DHL').find(x => x.weight_g >= +aw)
      if(d){
        let b = JSON.parse(d.unit_data)

        if(b.unit_weight == 'Kleinpaket' && productData.product_category_hazmat != null && productData.product_category_hazmat != ''){
          d = fulfilmentCostsData.filter(x => x.shipping_carrier == 'DHL').find(x => +JSON.parse(x.unit_data).weight_g >= +aw && JSON.parse(x.unit_data).unit_weight != 'Kleinpaket')
          b = JSON.parse(d.unit_data)
        }
      }

      setFulfillmentCostList([...fulfilmentCostsData.filter(x => x.shipping_carrier == 'DHL')])

      let dd = +aw >= 5000 ? fulfilmentCostsData.filter(x => x.shipping_carrier == 'DPD' && +x.status == 1).find(x => x.weight_g >= +aw) : null

      if(dd && (productData.product_category_hazmat == null || productData.product_category_hazmat == '')){
        let b = JSON.parse(dd.unit_data)

        setDPDUnit(b.unit_weight)
      }else{
        setDPDUnit('')
      }

    }

    if(FBADimensions && FBADimensions.getFBADimensions){
      setFBADimensionsList([...FBADimensions.getFBADimensions])
    }

  }, [fulfilmentCosts, FBADimensions, formData.article_weight, productData])

  const { localStorage } = window;
  
  let _arr = localStorage.getItem('idarray')
  if(_arr){
    _arr = _arr.split(',')
  }else{
    _arr = []
  }

  const silentError = _arr.indexOf(productData.product_parent_id) < 0 ? false : true


  const [changesMade, setChangesMade] = useState(false);
  const [fbaSize, setFbaSize] = useState(null);
  const [fbmSize, setFbmSize] = useState(
    productData.fbmsize? {...productData.fbmsize} : null
  );
    
  const [fbmSizes, setFbmSizes] = useState([]);
  
  useEffect(() => {
    if(data){
      setFbmSizes([...data.getFBMSizes])
    }
  }, [data])

  useEffect(() => {
    if(changesMade){

      window.onbeforeunload = function() {
        return true;
      };
  
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [changesMade]);

  useEffect(() => {
    let logistics_ve_unit_volume = undefined;
    if (
      formData.logistics_ve_width &&
      formData.logistics_ve_height &&
      formData.logistics_ve_depth
    ) {
      logistics_ve_unit_volume = Math.round(
        (+formData.logistics_ve_width *
          +formData.logistics_ve_height *
          +formData.logistics_ve_depth) /
          1000
      );
    }
    setFormData({
      ...formData,
      logistics_ve_unit_volume
    });
    // eslint-disable-next-line
  }, [
    formData.logistics_ve_width,
    formData.logistics_ve_height,
    formData.logistics_ve_depth
  ]);
 
  useEffect(() => {
    let article_unit_volume = undefined;
    if (formData.article_width && formData.article_height && formData.article_depth) {
      article_unit_volume = Math.round(
        (+formData.article_width * +formData.article_height * +formData.article_depth) / 1000
      );
    }

    const tempData = {...formData}
    tempData.article_unit_volume = article_unit_volume

    if(formData.article_width &&  formData.article_height && formData.article_depth){
      const min = Math.min.apply(Math, [+formData.article_width, +formData.article_height, +formData.article_depth])
      const max = Math.max.apply(Math, [+formData.article_width, +formData.article_height, +formData.article_depth])
      const calc = (max + min) / 10

      const calcSize = fbmSizes.filter(x => x.min <= calc && calc <= x.max)[0]

      if(calcSize){
        setFbmSize({...calcSize})
        
        tempData.fbm_size_id = +calcSize.id
      }

    }

    let dhlUnit = ''

    if(fulfillmentCostList){ 
      const aw = formData.article_weight;
      if(aw == '' || aw == 0) {
        dhlUnit = ''
        tempData.shipping_unit_id = null

      }else{
        let d = fulfillmentCostList.find(x => +JSON.parse(x.unit_data).weight_g >= +aw)


        if(d){
          let b = JSON.parse(d.unit_data)

          if(b.unit_weight == 'Kleinpaket' && productData.product_category_hazmat != null && productData.product_category_hazmat != ''){
            d = fulfillmentCostList.find(x => +JSON.parse(x.unit_data).weight_g >= +aw && JSON.parse(x.unit_data).unit_weight != 'Kleinpaket')
            b = JSON.parse(d.unit_data)
          }else if(b.unit_weight == 'Kleinpaket'){
            if(formData.article_depth > 80 || formData.article_height > 250 || formData.article_width > 353){
              d = fulfillmentCostList.find(x => +JSON.parse(x.unit_data).weight_g >= +aw && JSON.parse(x.unit_data).unit_weight != 'Kleinpaket')
              b = JSON.parse(d.unit_data)
            }
          }


          dhlUnit = b.unit_weight
          tempData.shipping_unit_id = +d.id
        }
      }
      
      
      setDHLUnit(dhlUnit)

    }

    if(FBADimensionsList){
      let f = false
      FBADimensionsList.forEach(x => {
        const d = x.dimensions.split('x').map(w => +w * 10).sort((a, b) => a - b)
        const minSize = Math.min.apply(Math, [+formData.article_width, +formData.article_height, +formData.article_depth]) * (1 + 0.025)

        if(!f){
          if(x.operator == '<='){
            if(+formData.article_weight <= +x.weight && minSize <= d[0]){
              setFbaSize(x)
              f = true              
            }
          }else if(x.operator == '<'){
            if(+formData.article_weight < +x.weight && minSize < d[0]){
              setFbaSize(x)
              f = true
            }
          }else{
            if(+formData.article_weight > +x.weight && minSize > d[0]){
              setFbaSize(x)
              f = true
            }
          }
          if(f){
            tempData.fba_size_id = +x.id
          }
        }
      })
    }

    setFormData({
      ...tempData
    });

  // eslint-disable-next-line
  }, [formData.article_width, formData.article_height, formData.article_depth, formData.article_weight, fbmSizes, fulfillmentCostList, FBADimensionsList]);

  const submitHandler = async event => {
    event.preventDefault();

    let priceCheckError = false;

    if(!silentError){
      if((productData.product_category_hazmat == null || productData.product_category_hazmat.length == 0)  && +formData.product_type_id !== 8){
        if(!window.confirm('I confirm that product doesn’t fall under the hazmat category')){
          return false;
        }else{
          if(_arr.indexOf(productData.product_parent_id) < 0){
            _arr.push(productData.product_parent_id)
          }
        }
      }

      if((productData.productPrices == null || productData.productPrices.length == 0)  && +formData.product_type_id !== 8){
        if(!window.confirm('I confirm that this product has no buy (net) price')){
          return false;
        }else{
          if(_arr.indexOf(productData.product_parent_id) < 0){
            _arr.push(productData.product_parent_id)
          }
        }
      }else{
        productData.productPrices.forEach((value, index) => {
          if(value.price_buy_net_actual_ct == null || value.price_buy_net_actual_ct == 0){
            priceCheckError = true
          }
        })
      }

      if(priceCheckError  && +formData.product_type_id !== 8) {
        if(!window.confirm('Some prices have no buy (net) price')){
          return false;
        }else{
          if(_arr.indexOf(productData.product_parent_id) < 0){
            _arr.push(productData.product_parent_id)
          }
        }
      }
    }


    setChangesMade(false);

    try {
      const { data } = await updateProductLogisticMutation({
        variables: { input: { ...formData, product_parent_id: productData.product_parent_id, fbm_size_id: +fbmSize?.id } }
      });

      if (!data.updateProductLogistic) {
        throw new Error('Server responded with no data');
      }

      
      if (data.updateProductLogistic.error) {
        setValidationError(data.updateProductLogistic.error)
        setErrorClass('warning')
      }else{
        dispatch({
          type: 'UPDATE',
          payload: {
            type: 'success',
            message: 'Recored was successfully updated'
          }
        });
        refetch();
      }

    } catch (err) {
      console.log(err)
      setError(err);
    }
  };

  const changeFieldHandler = event => {
    setChangesMade(true);
    const err = {...errorsList}
    const d = Object.keys(err).find(x => x == event.target.id)
    if(d){
      delete err[event.target.id]

      setErrorsList({...err})
    }

    if (formData){
      setFormData({
        ...formData,
        [event.target.id]: event.target.value
      });
    }
  };

  const changeSelectHandler = (option, data) => {
    setChangesMade(true);

    const err = {...errorsList}
    const d = Object.keys(err).find(x => x == data.name)
    if(d){
      delete err[data.name]

      setErrorsList({...err})
    }

    setFormData({
      ...formData,
      [data.name]: option && option.value
    });
  };

  useEffect(() => {
    updateFormData({logisticsInformation: formData})
  }, [formData])

  if (error || updateError) {
    throw new Error(error || updateError);
  }

  const isArchived = productData.status === 0 || productData.manufacturer.status === 0;

  return (
    <form onSubmit={submitHandler}>
      <fieldset disabled={!update}>
        <Error setError={() => setValidationError('')} error={validationErrror} className={errorClass}  close={true} />

        <div className="row row-cols-4">
          <div className="col-md-3">
            
            <div className="form-group">
              <label htmlFor="article_width">Article width (in mm) <span style={{ color: 'red' }}>*</span></label>
              <div class="input-group mb-3">
                  <input
                    type="number"
                    min={+productData.status === 1? 1 : 0}
                    id="article_width"
                    className={`form-control ${errorsList && errorsList['article_width'] ? 'invalid' : ''} `}
                    defaultValue={formData.article_width}
                    onChange={changeFieldHandler}
                    disabled={isArchived}
                    required
                    aria-describedby="basic-addon2"
                  />
                {showError('article_width')}

                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">{ formData.article_width ? Number(formData.article_width/10).toFixed(2) + ' cm' : '' } </span>
                </div>
              </div>
              
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="article_height">Article height (in mm) <span style={{ color: 'red' }}>*</span></label>
              <div class="input-group mb-3">
                <input
                  type="number"
                  min={+productData.status === 1? 1 : 0}
                  id="article_height"
                  className={`form-control ${errorsList && errorsList['article_height'] ? 'invalid' : ''} `}
                  defaultValue={formData.article_height}
                  onChange={changeFieldHandler}
                  disabled={isArchived}
                  required
                  aria-describedby="basic-addon3"
                />
                {showError('article_height')}

                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon3">{ formData.article_height ? Number(formData.article_height/10).toFixed(2) + ' cm' : '' } </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="article_depth">Article depth (in mm) <span style={{ color: 'red' }}>*</span></label>
            
              <div class="input-group mb-3">
                <input
                  type="number"
                  min={+productData.status === 1? 1 : 0}
                  id="article_depth"
                  className={`form-control ${errorsList && errorsList['article_depth'] ? 'invalid' : ''} `}
                  defaultValue={formData.article_depth}
                  onChange={changeFieldHandler}
                  required
                  disabled={isArchived}
                  aria-describedby="basic-addon4"
                />
                {showError('article_depth')}

                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon4">{ formData.article_depth ? Number(formData.article_depth/10).toFixed(2) + ' cm' : '' } </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="article_unit_volume">Article unit volume (in cm3) <span style={{ color: 'red' }}>*</span></label>
              <input
                type="number"
                min="0"
                id="article_unit_volume"
                className="form-control"
                defaultValue={formData.article_unit_volume}
                value={formData.article_unit_volume}
                onChange={changeFieldHandler}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="article_weight">Article weight (in g) <span style={{ color: 'red' }}>*</span></label>
              <div class="input-group mb-3">
                <input
                  type="number"
                  min={+productData.status === 1? 1 : 0}
                  id="article_weight"
                  className={`form-control ${errorsList && errorsList['article_weight'] ? 'invalid' : ''} `}
                  defaultValue={formData.article_weight}
                  onChange={changeFieldHandler}
                  disabled={isArchived}
                  required
                  aria-describedby="basic-addon5"
                />
                {showError('article_weight')}
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon5">{ formData.article_weight ? Number(formData.article_weight/1000).toFixed(2) + ' kg' : '' } </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <label htmlFor="article_packaging_type_id">Article packaging type <span style={{ color: 'red' }}>*</span></label>
            <Select
              name="article_packaging_type_id"
              options={convertDataToOptions(selectData.articlePackagingType)}
              defaultValue={getDefaultOptionFromValue(
                formData.article_packaging_type_id,
                selectData.articlePackagingType
              )}
              className={errorsList && errorsList['article_packaging_type_id'] ? 'invalid' : ''}
              onChange={changeSelectHandler}
              isClearable
              isDisabled={!update || isArchived}
              
            />
            <input
              tabIndex={-1}
              style={{ opacity: 0, height: 0 }}
              required={!formData.article_packaging_type_id}
            />

            {showError('article_packaging_type_id')}

          </div>          
          <div className="col-md-3">
            <label htmlFor="packaging_unit_logistical">Logistical packaging unit </label>
            <Select
              name="packaging_unit_logistical"
              options={convertDataToOptions(selectData.packagingUnits)}
              defaultValue={getDefaultOptionFromValue(
                formData.packaging_unit_logistical,
                selectData.packagingUnits
              )}
              onChange={changeSelectHandler}
              className={errorsList && errorsList['packaging_unit_logistical'] ? 'invalid' : ''}
              isClearable
              isDisabled={!update || isArchived}
            />
          </div>

            {showError('packaging_unit_logistical')}
          
        </div>

        <div className="log_divider"></div>

        <div className="row row-cols-4">
          <div className="col-md-3 custom-flex">
            <div>
              <label htmlFor="article_packaging_type_id">DPD size range</label>
              <input
                  type="text"
                  id="range"
                  className="form-control"
                  value={fbmSize ? `${fbmSize.min} - ${fbmSize.max.toFixed(2)} cm` : ''}
                  disabled={true}
                />
            </div>
            <div >
              <label htmlFor="article_packaging_type_id">DPD size</label>
              <input
                  type="text"
                  id="prime_size"
                  className="form-control"
                  value={fbmSize ? fbmSize.size : ''}
                  disabled={true}
                />
            </div>

          </div>
          <div className={`col-md-3 custom-flex`}>
            <div>
              <label htmlFor="article_packaging_type_id">DPD size code</label>
              <input
                  type="text"
                  id="prime_size_code"
                  className="form-control"
                  value={fbmSize ? fbmSize.uid : ''}
                  disabled={true}
                  />
            </div>
            <div >
              <label htmlFor="article_packaging_type_id">DPD unit</label>
              <input
                  type="text"
                  id="prime_size"
                  className="form-control"
                  value={(productData.product_category_hazmat == null || productData.product_category_hazmat == '') ? DPDUnit : ''}
                  disabled={true}
                />
            </div>
          </div>
          <div className="col-md-3">
            <label htmlFor="article_packaging_type_id" className='tooltipHeading'>
              DHL unit
              <div className="u_infoButton">
                  <img src="/assets/images/info-button.png" alt="" />
                  
                  <div className="infoBox">
                      <span>Hazmat goods ineligible for Kleinpaket</span>
                  </div>
              </div>  
            </label>
            <input
                type="text"
                id="prime_size_code"
                className="form-control"
                value={DHLUnit}
                disabled={true}
              />
          </div>
          <div className="col-md-3">
            <div>
              <label htmlFor="article_packaging_type_id">FBA unit</label>
              <input
                  type="text"
                  id="fba_unit_size"
                  className="form-control"
                  value={`${fbaSize ? fbaSize.units : ''} ${fbaSize ? fbaSize.dimensions : ''}`}
                  disabled={true}
                />
            </div>
          </div>
        </div>

        <div class="product-form__header pb-0 pl-0 mt-3">
          <h4 class="m-0">Logistical VE</h4>
          </div>
        <div className="log_divider"></div>

        <div className="row row-cols-4">
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="logistics_ve_width">Logistics VE width (in mm)  {productData.manufacturer.commission_type != 'No commission' && <span style={{ color: 'red' }}>*</span>} </label>
              <input /* Temorarily removing active product validations for logistical VE due to lack of data at the moment {+productData.status === 1? 1 : 0} */
                type="number"
                min="0"
                id="logistics_ve_width"
                className={`form-control ${errorsList && errorsList['logistics_ve_width'] ? 'invalid' : ''} `}
                defaultValue={formData.logistics_ve_width}
                onChange={changeFieldHandler}
                disabled={isArchived}
                required
              />
                {showError('logistics_ve_width')}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="logistics_ve_height">Logistics VE height (in mm) {productData.manufacturer.commission_type != 'No commission' && <span style={{ color: 'red' }}>*</span>}</label>
              <input /* {+productData.status === 1? 1 : 0} */
                type="number"
                min="0"
                id="logistics_ve_height"
                className={`form-control ${errorsList && errorsList['logistics_ve_height'] ? 'invalid' : ''} `}
                defaultValue={formData.logistics_ve_height}
                onChange={changeFieldHandler}
                disabled={isArchived}
                required
              />
                {showError('logistics_ve_height')}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="logistics_ve_depth">Logistics VE depth (in mm) {productData.manufacturer.commission_type != 'No commission' && <span style={{ color: 'red' }}>*</span>}</label>
              <input  /* {+productData.status === 1? 1 : 0} */
                type="number"
                min="0"
                id="logistics_ve_depth"
                className={`form-control ${errorsList && errorsList['logistics_ve_depth'] ? 'invalid' : ''} `}
                defaultValue={formData.logistics_ve_depth}
                onChange={changeFieldHandler}
                disabled={isArchived}
                required
              />
                {showError('logistics_ve_depth')}

            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="logistics_ve_unit_volume">Logistics VE unit volume (in cm3) {productData.manufacturer.commission_type != 'No commission' && <span style={{ color: 'red' }}>*</span>}</label>
              <input
                type="number"
                min="0"
                id="logistics_ve_unit_volume"
                className="form-control"
                defaultValue={formData.logistics_ve_unit_volume}
                value={formData.logistics_ve_unit_volume}
                onChange={changeFieldHandler}
                disabled={true}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label htmlFor="logistics_ve_weight">Logistics VE weight (in g) {productData.manufacturer.commission_type != 'No commission' && <span style={{ color: 'red' }}>*</span>}</label>
              <input /* {+productData.status === 1? 1 : 0} */
                type="number"
                min="0"
                id="logistics_ve_weight"
                className={`form-control ${errorsList && errorsList['logistics_ve_weight'] ? 'invalid' : ''} `}
                defaultValue={formData.logistics_ve_weight}
                onChange={changeFieldHandler}
                disabled={isArchived}
                required
              />
                {showError('logistics_ve_weight')}
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  );
};

export default LogisticsInformationForm;
